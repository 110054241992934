import React from "react"
import Layout from "../../components/layout"
import ArticleComponents from "../../components/content-pages/article-components"
import { graphql } from "gatsby"
import SEO from "../../components/seo"

const ArticleTemplate = ({ data, location }) => {
  const { article_title } = data.sanityArticle
  return (
    <Layout>
      <SEO title={article_title} url={location.pathname} />
      <ArticleComponents data={data} />
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query($slug: String!, $articleTypeSlug: String!) {
    allSanityArticleType(
      filter: { slug: { current: { ne: $articleTypeSlug } } }
    ) {
      nodes {
        article_type_title
        slug {
          current
        }
      }
    }

    allSanityArticle(
      filter: {
        article_meta_data: {
          article_type_ref: { slug: { current: { eq: $articleTypeSlug } } }
        }
      }
    ) {
      nodes {
        _id
        slug {
          current
        }
        title: article_title
        meta: article_meta_data {
          article_type_ref {
            article_type_title
          }
        }
        _type
        image: article_image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    sanityArticle(slug: { current: { eq: $slug } }) {
      article_related_items {
        ... on SanityArticle {
          _id
          _type
          slug {
            current
          }
          article_title
        }
        ... on SanityRecipe {
          _id
          _type
          recipe_title
          slug {
            current
          }
        }
        ... on SanityProduct {
          product_flavor_profile {
            score {
              title
              score
            }
          }
          _id
          product_name
          _type
          product_image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          product_variants {
            variants {
              variant_info_price
            }
          }
          slug {
            current
          }
          product_collection {
            slug {
              current
            }
          }
        }
      }
      article_content {
        ... on SanityVideoUrl {
          _key
          _type
          url
        }
        ... on SanityArticleSection {
          _key
          _type
          _rawSectionContent(resolveReferences: { maxDepth: 6 })
          section_aside
          section_title
        }
        ... on SanityImage {
          _key
          _type
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      article_image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      article_intro
      article_meta_data {
        article_type_ref {
          article_type_title
        }
      }
      article_title
    }
  }
`
